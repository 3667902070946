
import {defineComponent} from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import {toolbarWidthFluid} from "@/core/helpers/config";
import router from "@/router";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
    routeCreateName: String,
  },
  components: {
    Dropdown1,
  },
  setup(props) {
    const route = useRouter();
    const pushRouteCreate = () => {
      route.push({name: props.routeCreateName})
    }
    return {
      toolbarWidthFluid,
      pushRouteCreate
    };
  },
});
