
import { defineComponent, ref, computed} from "vue";
// import { useStore } from "vuex";
// import { useRouter } from 'vue-router';
import i18n from "@/core/plugins/i18n";


export default defineComponent({
  name: "LangPopup",
  setup() {

    // const store = useStore();
    // const router = useRouter();
    // var language = ref(i18n.global.locale.value)

    const setLang = (lang) => {
      localStorage.setItem('lang', lang)
      // i18n.global.locale.value = lang
      // var link  = <HTMLLinkElement> document.getElementById('lang-switcher');
      // link.id   = "lang-switcher";
      // link.rel  = 'stylesheet';
      // link.type = 'text/css';
      // link.href = localStorage.lang == 'fr'?'css/style.rtl.css':'css/style.css';
      // link.media = 'all';
      window.location.reload();
    };


    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "media/flags/saudi-arabia.svg",
        name: "Arabic",
      },
      ar: {
        flag: "media/flags/saudi-arabia.svg",
        name: "Arabic",
      },
    };

    const currentLanguage = (lang) => {
      return i18n.global.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.global.locale.value];
    });





    return {
      // language,
      countries,
      setLang,
      currentLanguage,
      currentLangugeLocale,
    };
  },
});
