const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "",
        fontIcon: "fa fa-solid fa-gauge",
      },
    ],
  },
  {
    heading: "apps",
    route: "/apps",
    pages: [
      {
        heading: "Countries",
        route: "/countries",
        svgIcon: "media/icons/custom/Countries.svg",
        // fontIcon: "fa fa-flag",
        permission: 'countries.index',
      },
      {
        heading: "Governorates",
        route: "/governorates/all",
        svgIcon: "media/icons/custom/government.svg",
        // fontIcon: "fa fa-flag",
        permission: 'governorates.index',
      },
      {
        heading: "Cities",
        route: "/cities/all",
        svgIcon: "media/icons/custom/Cities.svg",
        // fontIcon: "fa fa-flag",
        permission: 'cities.index',
      },

      {
        sectionTitle: "Users",
        svgIcon: "media/icons/custom/Users.svg",
        fontIcon: "bi-person",
        permission: 'users.index|users.exceed-wallet-limit',
        sub: [
          {
            heading: "Users",
            route: "/users/all",
            permission: 'users.index',
          },

          {
            heading: "Exceeded Wallet",
            route: "/user-exceed-wallet-limit/all",
            permission: 'users.exceed-wallet-limit',
          },


        ],
      },

      {
        sectionTitle: "Providers",
        svgIcon: "media/icons/custom/Provider.svg",
        fontIcon: "bi-person",
        permission: 'providers.index|providers.profile-picture-request|providers.location',
        sub: [
          {
            heading: "Providers",
            route: "/providers/all",
            permission: 'providers.index',
          },
          {
            heading: "Profile picture requests",
            route: "/provider-data-changes/all",
            permission: 'providers.profile-picture-request',
          },
          {
            heading: "Documents expired",
            route: "/provider-documents-expired/all",
            permission: 'providers.documents-expired',
          },
          {
            heading: "Exceeded Wallet",
            route: "/provider-exceed-wallet-limit/all",
            permission: 'providers.exceed-wallet-limit',
          },
          {
            heading: "Providers Location",
            route: "/providers-location",
            permission: 'providers.location',
          },

        ],
      },
      {
        heading: "Sliders",
        route: "/sliders/all",
        svgIcon: "media/icons/custom/sliders.svg",
        // fontIcon: "fa fa-sliders-h",
        permission: 'sliders.index',
      },
      {
        heading: "Banks",
        route: "/banks/all",
        svgIcon: "media/icons/custom/Banks.svg",
        // fontIcon: "fa fa-sliders-h",
        permission: 'banks.index',
      },
      {
        heading: "Onboardings",
        route: "/onboardings/all",
        svgIcon: "media/icons/custom/airplane.svg",
        // fontIcon: "fa fa-list",
        permission: 'onboardings.index',
      },
      {
        heading: "Units",
        route: "/units/all",
        svgIcon: "",
        fontIcon: "fa fa-balance-scale",
        permission: 'units.index',
      },
      {
        heading: "Options",
        route: "/options/all",
        svgIcon: "media/icons/custom/options.svg",
        // fontIcon: "fa fa-list",
        permission: 'options.index',
      },
      {
        heading: "Categories",
        route: "/categories/all",
        svgIcon: "",
        fontIcon: "fa fa-list",
        permission: 'categories.index',
      },
      {
        heading: "Services",
        route: "/services/all",
        svgIcon: "media/icons/custom/service.svg",
        // fontIcon: "fa fa-wrench",
        permission: 'services.index',
      },
      {
        heading: "Coupons",
        route: "/coupons/all",
        svgIcon: "",
        fontIcon: "fa fa-gift",
        permission: 'coupons.index',
      },
      {
        heading: "Orders",
        route: "/orders",
        svgIcon: "",
        fontIcon: "fa fa-list",
        permission: 'orders.index',
      },

      {
        heading: "Faqs",
        route: "/faqs/all",
        svgIcon: "",
        fontIcon: "fa fa-question-circle",
        permission: 'faqs.index',
      },
      {
        heading: "Contacts",
        route: "/contacts/all",
        svgIcon: "",
        fontIcon: "fa fa-phone",
        permission: 'contacts.index',
      },
      {
        heading: "Reject Reasons",
        route: "/reject-reasons/all",
        svgIcon: "",
        fontIcon: "fa fa-ban",
        permission: 'reject-reasons.index',
      },

      {
        sectionTitle: "Settings",
        route: "/settings",
        svgIcon: "",
        fontIcon: "fa fa-cog",
        permission: 'settings.contact-us|settings.order-setting|settings.privacy-policy|settings.terms-and-conditions|settings.about-us|settings.payment-setting|settings.home-sections',
        sub: [
          {
            heading: "Contact Us",
            route: "/settings/contact-us",
            permission: 'settings.contact-us',
          },
          {
            heading: "Order Settings",
            route: "/settings/order-setting",
            permission: 'settings.order-setting',
          },
          {
            heading: "Privacy Policy",
            route: "/settings/privacy-policy",
            permission: 'settings.privacy-policy',
          },
          {
            heading: "Terms And Conditions",
            route: "/settings/terms-and-conditions",
            permission: 'settings.terms-and-conditions',
          },
          {
            heading: "About Us",
            route: "/settings/about-us",
            permission: 'settings.about-us',
          },
          {
            heading: "Payment Setting",
            route: "/settings/payment-setting",
            permission: 'settings.payment-setting',
          },
          {
            heading: "Social Setting",
            route: "/settings/social-setting",
            permission: 'settings.social-setting',
          },
          {
            heading: "Home Sections",
            route: "/home-sections",
            permission: 'settings.home-sections',
          },
          {
            heading: "Sms Setting",
            route: "/settings/sms-setting",
            permission: 'settings.sms-setting',
          },
          {
            heading: "Pubnub Setting",
            route: "/settings/pubnub-setting",
            permission: 'settings.pubnub-setting',
          },
        ],
      },
      {
        heading: "Notifications",
        route: "/notifications/all",
        svgIcon: "",
        fontIcon: "fa fa-bell",
      },
      {
        sectionTitle: "Administrative",
        svgIcon: "",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Admins",
            route: "/admins/all",
          },
          {
            heading: "Roles",
            route: "/roles/all",
          },

        ],
      },

    ],
  },
];

export default DocMenuConfig;
